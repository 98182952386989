import React from 'react'
import Helmet from 'react-helmet'
import styled from '@emotion/styled'
import Fade from 'react-reveal/Fade'
import { Global, css } from '@emotion/core'

import Layout from '../organisms/layout'
import Theme, { Mq } from '../themes/default'
import { SubpageHero } from '../molecules/subpage-hero'

const ContentStyles = css`
  h1 {
    font-size: 1.8rem;
    margin-bottom: 1rem;

    ${Mq.md} {
      font-size: 2.625rem;
    }
  }

  h3 {
    font-size: 1.3rem;
    margin-bottom: 1rem;

    ${Mq.md} {
      font-size: 1.5rem;
    }
  }

  h4 {
    font-size: 1.1rem;
    margin-bottom: 1rem;

    ${Mq.md} {
      font-size: 1.3rem;
    }
  }

  p {
    font-size: 0.9375rem;
    line-height: 1.5625rem;
    margin-bottom: 1.5rem;

    ${Mq.md} {
      margin-bottom: 2rem;
      font-size: 1.0625rem;
      line-height: 1.6875rem;
    }
  }

  a {
    color: ${Theme.colors.primary};
  }
`

const TermsConditionsContainer = styled.div`
  padding: 1rem 5%;

  ${Mq.md} {
    padding: 3rem 3% 7rem;
    margin: 0 5%;
  }
  ${Mq.xxl} {
    margin: 0 15%;
  }
`

type TermsConditionsPageProps = {
  children: JSX.Element | JSX.Element[]
}

const TermsConditionsPage = ({ children } : TermsConditionsPageProps) => (
  <Layout hideNavItems>
    <Global styles={ContentStyles} />
    <Helmet><meta name="robots" content="noindex"></meta></Helmet>
    <SubpageHero title="Terms & Conditions" />
    <Fade bottom={true} cascade={true} distance={Theme.fadeDistance}>
      <TermsConditionsContainer>{children}</TermsConditionsContainer>
    </Fade>
  </Layout>
)

export default TermsConditionsPage
